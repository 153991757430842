// todo disabled
// todo hyperlink hover
// todo icon position

.chip {
  align-items: center;
  background-color: $chip-bg;
  border: 0;
  border-radius: $chip-height / 2;
  color: $chip-color;
  display: inline-flex;
  font-size: $chip-font-size;
  //font-weight: $chip-font-weight;
  height: $chip-height;
  justify-content: center;
  letter-spacing: $letter-spacing-body-2;
  line-height: $chip-line-height;
  margin: $spacer-xs;
  padding: $chip-padding-y $chip-padding-x;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &:empty {
    display: none;
  }

  // TODO: after element instead of bg-image
  .close {
    background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="#666" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'));
    height: 18px;
    margin-right: ($chip-inner-spacer-x * 2) - $chip-padding-x;
    margin-left: $chip-inner-spacer-x * 2;
    order: 1;
    width: 18px;
  }

  > [class*='material-icons'] {
    @extend .chip-img;

    color: $black-secondary;
    font-size: $chip-icon-size;
    line-height: 1;
  }
}

@each $color, $values in $theme-colors {
  .chip-#{$color},
  .chip-#{$color}:hover {
    background-color: theme-color($color);
    color: color-yiq(theme-color($color));
  }
}

// Outline
.chip-outline {
  background: transparent;
  border: 1px solid $black-divider;

  .close {
    background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="#9e9e9e" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'));
  }
}

// Chip action
.chip-action {
  //@include transition-standard(background-color);
  transition: background-color 15ms linear;

  @include hover-focus-active {
    color: $chip-color;
    text-decoration: none;
  }

  // stylelint-disable
  &:not(.disabled) {
    &:hover {
      background-color: $chip-bg-hover;
    }

    &:active {
      background-color: $chip-bg-active;
    }

    &:focus {
      outline: 0;
    }

    &.chip-outline {
      &:hover {
        background-color: $chip-outline-bg-hover;
      }

      &:active {
        background-color: $chip-outline-bg-active;
      }
    }
  }
  // stylelint-enable
}

// Chip choice
[type='radio']:checked + .chip-choice {
  background-color: rgba(theme-color(primary), $overlay-activated-colored-opacity);
  color: theme-color(primary);

  &:hover,
  &:active {
    background-color: rgba(theme-color(primary), $overlay-pressed-colored-opacity);
  }
}

[type='radio']:checked + .chip-choice.chip-outline {
  background-color: rgba(theme-color(primary), $overlay-activated-white-opacity);

  &:hover,
  &:active {
    background-color: rgba(theme-color(primary), $overlay-pressed-white-opacity);
  }
}

// Chip filter
[type='checkbox'] + .chip-filter:not(.chip-outline) {
  color: $black-secondary;
}

[type='checkbox']:checked + .chip-filter {
  background-color: rgba($black, $overlay-selected-colored-opacity);
  color: $black-primary;
}

[type='checkbox']:checked + .chip-filter.chip-outline {
  background-color: rgba($black, $overlay-selected-white-opacity);
}

[type='checkbox'] + .chip-filter::before {
  content: '';
  font-family: var(--font-family-material-icons); // stylelint-disable-line font-family-no-missing-generic-family-keyword
  transition: margin 100ms ease-in;
}

[type='checkbox']:checked + .chip-filter::before {
  content: 'check';
  font-size: 1.125rem;
  margin-right: $chip-inner-spacer-x;
  transform: translateX(-$chip-inner-spacer-x);
  transition: margin 100ms ease-in;
}

.chip-img {
  border-radius: $chip-icon-size / 2;
  flex-shrink: 0;
  height: $chip-icon-size;
  margin-right: $chip-inner-spacer-x;
  margin-left: -$chip-inner-spacer-x;
  order: -1;
  width: $chip-icon-size;
}

.chip-icon {
  @extend .chip-img;

  @include text-truncate;

  align-items: center;
  background-color: $chip-icon-bg;
  color: color-yiq($chip-icon-bg);
  display: inline-flex;
  font-size: $chip-icon-font-size;
  font-style: normal;
  font-weight: $chip-font-weight;
  justify-content: center;
}

// Size
.chip-sm {
  height: 1.75rem;
}

.chip-xs {
  height: 1.5rem;
}

// Disabled
.chip.disabled,
input:disabled + .chip {
  opacity: 0.38;
}
