// todo : btn-group disabled

.btn-group,
.btn-group-vertical {
  @include border-radius($btn-border-radius);

  background-color: $btn-group-bg;
  //border: 1px solid $black-divider;
  box-shadow: map-get($btn-elevation-shadow, shadow);
  display: inline-flex;
  position: relative;
  //vertical-align: middle;

  @each $color,
    $values in $theme-colors {
    > .btn-#{$color} {
      &.disabled,
      &:disabled {
        background-color: theme-color-light($color);
        color: color-yiq(theme-color-light($color));
      }
    }
  }

  > .btn {
    @include transition-standard(border-color, opacity);

    box-shadow: none;
    flex: 1 1 auto;
    min-width: 0;
    padding-right: $btn-group-padding-x;
    padding-left: $btn-group-padding-x;
    position: relative;

    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }

    /* &.active,
    &:active {
      background-color: rgba($black,$overlay-activated-white-opacity);
    } */

    &:disabled,
    &.disabled {
      opacity: $btn-group-toggle-opacity;
    }

    // stylelint-disable
    &[class*='btn-outline'] {
      border: 0;
      //opacity: $btn-group-toggle-opacity;

      &.active,
      &:active {
        background-color: rgba($black, $overlay-activated-white-opacity);
        //opacity: 1;
      }

      &.disabled,
      &:disabled {
        //opacity: 1;
      }
    }
    // stylelint-enable
  }

  > .btn-group,
  > .btn-group-vertical {
    @include border-radius(0);

    background-color: transparent;
    box-shadow: none;
  }
}

.btn-group {
  &.show > .dropdown-toggle {
    box-shadow: none;
  }

  > .btn,
  > .btn-group {
    margin-left: ($btn-group-inner-spacer-x * -1);
  }

  > .btn:first-child,
  > .btn-group:first-child {
    margin-left: 0;
  }

  > .btn:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    @include border-left-radius(0);
  }

  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn,
  > .dropdown-toggle:not(:last-of-type) {
    @include border-right-radius(0);
  }
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  > .btn,
  > .btn-group {
    margin-top: ($btn-group-inner-spacer-y * -1);
    margin-left: 0;
    width: 100%;
  }

  > .btn:first-child,
  > .btn-group:first-child {
    margin-top: 0;
  }

  // Reset rounded corners
  > .btn:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    @include border-top-radius(0);
  }

  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn,
  > .dropdown-toggle:not(:last-of-type) {
    @include border-bottom-radius(0);
  }
}

.btn-group-fluid {
  background-color: transparent;
  box-shadow: none;
}

.btn-group-sm > .btn {
  @extend %btn-sm;
}

.btn-group-lg > .btn {
  @extend %btn-lg;
}

// Checkbox and radio options

[data-toggle='buttons'] {
  > .btn,
  > .btn-group > .btn {
    margin-bottom: 0;

    [type='checkbox'],
    [type='radio'] {
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
      position: absolute;
    }
  }
}

/* .btn-sm + .dropdown-toggle-split {
  padding-right: $btn-padding-x-sm * .75;
  padding-left: $btn-padding-x-sm * .75;
}

.btn-lg + .dropdown-toggle-split {
  padding-right: $btn-padding-x-lg * .75;
  padding-left: $btn-padding-x-lg * .75;
} */

// Toolbar

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}

// Checkbox and radio options

.btn-group-toggle {
  > .btn,
  > .btn-group > .btn {
    margin-bottom: 0; // Override default `<label>` value

    [type='radio'],
    [type='checkbox'] {
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
      position: absolute;
    }
  }
}
