.custom-range {
  appearance: none;
  background-color: transparent;
  height: 3rem; // 48px
  padding: 0; // Need to reset padding
  width: 100%;

  &:hover {
    cursor: $slider-track-cursor;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-thumb { box-shadow: none; }
    &::-moz-range-thumb { box-shadow: none; }
    &::-ms-thumb { box-shadow: none; }
  }

  &:active {
    outline: 0;

    &::-webkit-slider-thumb { box-shadow: $slider-thumb-active-box-shadow; }

    &::-moz-range-thumb,
    &::-ms-thumb { box-shadow: $slider-thumb-active-box-shadow; }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background-color: $slider-thumb-bg;
    border: $slider-thumb-border;
    border-radius: $slider-thumb-border-radius;
    height: $slider-thumb-height;
    margin-top: ($slider-track-height - $slider-thumb-height) / 2; // Webkit specific
    transition: $slider-thumb-transition;
    width: $slider-thumb-width;

    &:hover {
      box-shadow: $slider-thumb-hover-box-shadow;
      transition: $slider-thumb-transition;
    }

    &:active {
      background-color: $slider-thumb-active-bg;
      box-shadow: $slider-thumb-active-box-shadow;
      transition: $slider-thumb-transition;
    }
  }

  &::-webkit-slider-runnable-track {
    background-color: $slider-track-bg;
    border-color: transparent;
    border-radius: $slider-track-border-radius;
    color: transparent;
    cursor: $slider-track-cursor;
    height: $slider-track-height;
    width: $slider-track-width;
  }

  &::-moz-range-thumb {
    -moz-appearance: none; // stylelint-disable-line
    background-color: $slider-thumb-bg;
    border: $slider-thumb-border;
    border-radius: $slider-thumb-border-radius;
    height: $slider-thumb-height;
    transition: $slider-thumb-transition;
    width: $slider-thumb-width;

    &:hover {
      box-shadow: $slider-thumb-hover-box-shadow;
      transition: $slider-thumb-transition;
    }

    &:active {
      background-color: $slider-thumb-active-bg;
      box-shadow: $slider-thumb-active-box-shadow;
      transition: $slider-thumb-transition;
    }
  }

  &::-moz-range-track {
    background-color: $slider-track-bg;
    border-color: transparent; // Firefox specific?
    border-radius: $slider-track-border-radius;
    color: transparent;
    cursor: $slider-track-cursor;
    height: $slider-track-height;
    width: $slider-track-width;
  }

  &::-moz-range-progress {
    background: $slider-thumb-bg;
    height: $slider-track-height;
  }

  &::-ms-thumb {
    background-color: $slider-thumb-bg;
    border: $slider-thumb-border;
    border-radius: $slider-thumb-border-radius;
    height: $slider-thumb-height;
    margin-top: 0; // Edge specific
    margin-right: $slider-thumb-active-box-shadow-width; // Workaround that overflowed box-shadow is hidden.
    margin-left: $slider-thumb-active-box-shadow-width;  // Workaround that overflowed box-shadow is hidden.
    width: $slider-thumb-width;

    &:hover {
      box-shadow: $slider-thumb-hover-box-shadow;
    }

    &:focus {
      box-shadow: none;
    }

    &:active {
      background-color: $slider-thumb-active-bg;
      box-shadow: $slider-thumb-active-box-shadow;
    }
  }

  &::-ms-track {
    background-color: transparent;
    border-color: transparent;
    border-width: $slider-thumb-height / 2;
    color: transparent;
    cursor: $slider-track-cursor;
    height: $slider-track-height;
    width: $slider-track-width;
  }

  &::-ms-fill-lower {
    background-color: $slider-thumb-bg;
    border-radius: $slider-track-border-radius;
  }

  &::-ms-fill-upper {
    background-color: $slider-track-bg;
    border-radius: $slider-track-border-radius;
    margin-right: 15px; // arbitrary?
  }

  &:disabled {
    cursor: default;

    &::-webkit-slider-thumb {
      background-color: $slider-thumb-disabled-bg;
      box-shadow: 0 0 0 4px #ffffff;
    }

    &::-webkit-slider-runnable-track {
      background: $slider-track-bg-disabled;
      cursor: default;
    }

    &::-moz-range-thumb,
    &:hover::-moz-range-thumb {
      background-color: $slider-thumb-disabled-bg;
      box-shadow: 0 0 0 4px #ffffff;
    }

    &::-moz-range-track {
      background: $slider-track-bg-disabled;
      cursor: default;
    }

    &::-moz-range-progress {
      background: $slider-thumb-disabled-bg;
    }

    &::-ms-thumb {
      background: $slider-thumb-disabled-bg;
    }

    &::-ms-fill-lower {
      background: $slider-thumb-disabled-bg;
    }

    &::-ms-fill-upper {
      background-color: $slider-track-bg-disabled;
      margin-right: 15px; // arbitrary?
    }
  }
}
