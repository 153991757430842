// Todo: expansion panel with different background colors
// Todo : lga.active:hover/focus/active
// Todo : li divider

.list-group {
  border: 1px solid $black-divider;
  border-radius: $expansion-panel-border-radius;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding: $spacer-sm 0;
}

.list-group-item {
  background-color: $expansion-panel-bg;
  border: 0;
  // border-radius: 0 2rem 2rem 0;
  color: $expansion-panel-color;
  display: block;
  font-size: $expansion-panel-font-size;
  letter-spacing: $expansion-panel-letter-spacing;
  line-height: $expansion-panel-line-height;
  // margin-bottom: -$list-group-border-width;
  min-height: $expansion-panel-height;
  padding: $expansion-panel-padding-y $expansion-panel-padding-x;
  position: relative;
  transition: background-color 15ms linear;

  @include hover-focus-active {
    //color: $expansion-panel-color;
    text-decoration: none;
  }

  &.active {
    background-color: $expansion-panel-bg-selected;
    color: $expansion-panel-color;
    z-index: 2;

    &:hover {
      background-color: $expansion-panel-bg-primary-hover;
    }

    &:focus {
      background-color: $expansion-panel-bg-primary-focus;
    }
  }

  &.disabled,
  &:disabled {
    background-color: $expansion-panel-bg;
    color: $expansion-panel-color-disabled;
    pointer-events: none;
  }

  .card & {
    padding-right: $card-padding-x;
    padding-left: $card-padding-x;
  }
}

.list-group-item-action {
  //color: $expansion-panel-color;
  text-align: inherit;
  width: 100%;

  @include hover-focus {
    &:not(.active) {
      color: $expansion-panel-color;
      text-decoration: none;
      z-index: 1;
    }
  }

  &:hover {
    background-color: $expansion-panel-bg-hover;
  }

  &:focus {
    //background-color: $expansion-panel-bg-focus;
    outline: 0;
  }

  &:active {
    background-color: $expansion-panel-bg-active;
  }

  &:disabled,
  &.disabled {
    background-color: $expansion-panel-bg;
    color: $expansion-panel-color-disabled;
  }
}

[class*='list-group-horizontal'] {
  padding: 0;

  .list-group-item {
    border-right: 1px solid $black-divider;
    margin-bottom: 0;

    &:first-child {
      @include border-left-radius($expansion-panel-border-radius);
    }

    &:last-child {
      @include border-right-radius($expansion-panel-border-radius);

      border-right: 0;
      margin-right: 0;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .list-group-horizontal#{$infix} {
      flex-direction: row;
    }
  }
}

@each $color, $values in $theme-colors {
  .list-group-item-#{$color} {
    background-color: theme-color-light($color);
    color: color-yiq(theme-color-light($color));

    &.active {
      background-color: theme-color($color);
      color: color-yiq(theme-color($color));
    }

    &.list-group-item-action {
      @include hover-focus-active {
        background-color: theme-color($color);
        color: color-yiq(theme-color($color));
      }
    }
  }
}

// Expansion panel

.expansion-panel {
  @include transition-standard(background-color, color, margin);

  padding: 0;

  &.show {
    border-radius: $expansion-panel-border-radius;

    &:not(:first-child) {
      margin-top: $expansion-panel-margin-y;

      .card & {
        margin-top: 0;
      }
    }

    &:not(:last-child) {
      margin-bottom: $expansion-panel-margin-y;

      .card & {
        margin-bottom: 0;
      }
    }

    + .expansion-panel {
      @include border-top-radius($expansion-panel-border-radius);

      &.show {
        margin-top: 0;
      }

      .card & {
        @include border-top-radius(0);
      }
    }
  }

  &.show-predecessor {
    @include border-bottom-radius($expansion-panel-border-radius);

    .card & {
      @include border-bottom-radius(0);
    }
  }

  .card & {
    padding-right: 0;
    padding-left: 0;
  }
}

.expansion-panel-body {
  padding: $expansion-panel-inner-spacer-y $expansion-panel-padding-x;

  .card & {
    padding-right: $card-padding-x;
    padding-left: $card-padding-x;
  }
}

.expansion-panel-footer {
  align-items: flex-end;
  border-top: $expansion-panel-border-width solid $expansion-panel-border-color;
  display: flex;
  justify-content: flex-end;
  padding: $expansion-panel-inner-spacer-y $expansion-panel-footer-padding-x ($expansion-panel-inner-spacer-y - $expansion-panel-footer-inner-spacer-y) ($expansion-panel-footer-padding-x - $expansion-panel-footer-inner-spacer-x);

  .card & {
    padding-right: $card-padding-x;
    padding-left: ($card-padding-x - $expansion-panel-footer-inner-spacer-x);
  }

  .btn {
    margin-bottom: $expansion-panel-footer-inner-spacer-y;
    margin-left: $expansion-panel-footer-inner-spacer-x;
    min-width: $expansion-panel-footer-btn-min-width;
    padding-right: $expansion-panel-footer-inner-spacer-x;
    padding-left: $expansion-panel-footer-inner-spacer-x;

    .card & {
      padding-right: ($card-padding-x - $expansion-panel-footer-padding-x);
      padding-left: ($card-padding-x - $expansion-panel-footer-padding-x);
    }
  }
}

.expansion-panel-icon {
  flex-shrink: 0;
  margin-left: $expansion-panel-inner-spacer-x;

  .collapsed-hide {
    [data-toggle='collapse'].collapsed & {
      display: none;
    }
  }

  .collapsed-show {
    [data-toggle='collapse']:not(.collapsed) & {
      display: none;
    }
  }
}

.expansion-panel-toggler {
  align-items: center;
  color: inherit;
  display: flex;
  justify-content: space-between;
  min-height: $expansion-panel-height;
  padding: $expansion-panel-padding-y $expansion-panel-padding-x;
  text-align: inherit;
  width: 100%;

  @include hover-focus-active {
    background-color: $expansion-panel-bg-active;
    color: $expansion-panel-color;
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    background-color: $expansion-panel-bg;
    color: $expansion-panel-color-disabled;
  }

  &:focus {
    outline: 0;
  }

  .card & {
    padding-right: $card-padding-x;
    padding-left: $card-padding-x;
  }
}

// Flush

.list-group-flush {
  border: 0;
  border-radius: 0;
  padding: 0;
}
