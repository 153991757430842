/* djibe addition */
html,
[data-spy='scroll'] {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html,
  [data-spy='scroll'] {
    scroll-behavior: auto;
  }
}
