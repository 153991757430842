// Btn flat and outlined
// Material guidelines : https://material.io/design/components/buttons.html#specs
// Material Web component : https://material-components.github.io/material-components-web-catalog/#/component/button?type=text
[class*='btn-flat'],
.btn-link,
.card-link,
.modal .btn {
  padding: $btn-padding-y $btn-flat-padding-x;
}

[class*='btn-outline'] {
  border: 1px solid currentColor;
  padding: $btn-padding-y calc(#{$btn-padding-x} - 1px);

  &:disabled,
  &.disabled {
    border: 1px solid $btn-color-disabled;
  }
}

[class*='btn-flat'],
[class*='btn-outline'],
.btn-link,
.card-link,
.modal .btn {
  background: transparent;
  box-shadow: none;

  @include hover-focus-active {
    box-shadow: none;
    color: $black-primary;
  }

  &:hover {
    background-color: rgba(0, 0, 0, $overlay-hover-white-opacity);
  }

  &:active {
    background-color: rgba(0, 0, 0, $overlay-pressed-white-opacity);
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
  }
}

@each $color,
  $values in $theme-colors {
  .btn-flat-#{$color},
  .btn-outline-#{$color},
  .modal .btn-#{$color} {
    @include plain-hover-focus {
      color: theme-color($color);
    }

    &:hover {
      background-color: rgba(theme-color($color), $overlay-hover-white-opacity);
    }

    &:focus {
      background-color: rgba(theme-color($color), $overlay-focus-white-opacity);
    }

    &:active {
      background-color: rgba(theme-color($color), $overlay-pressed-white-opacity);
    }

    &:disabled,
    &.disabled {
      background-color: transparent;
      color: $btn-color-disabled;
    }
  }

  .btn-outline-#{$color} {
    border-color: theme-color($color);
  }
}

.btn-link,
.card-link {
  @extend .btn-flat-primary;
}

.btn-flat-light,
.btn-outline-light {
  @include hover-focus {
    background-image: linear-gradient(to bottom, $btn-overlay-inverse, $btn-overlay-inverse);
  }

  &.active,
  &:active {
    background-color: $btn-bg-active-inverse;
  }
}

// Btn-icon addition
.btn-icon {
  border-radius: 50%;
  box-shadow: none;
  color: initial;
  font-size: 1.5rem;
  height: $btn-icon-width;
  min-width: 0;
  padding: 0;
  transition: background-color 15ms linear;
  width: $btn-icon-width;

  &.btn-sm {
    height: $btn-icon-width-sm;
    width: $btn-icon-width-sm;
  }

  &.btn-xs {
    height: $btn-icon-width-xs;
    width: $btn-icon-width-xs;
  }

  &:hover,
  &:focus,
  &:active {
    background: rgba($black, $overlay-hover-white-opacity);
    box-shadow: none;
    color: initial;
  }

  &:focus {
    background: rgba($black, $overlay-focus-white-opacity);
  }

  &:active {
    background: rgba($black, $overlay-pressed-white-opacity);
  }
}
