[class*='material-icons'] {
  //@include reset-material-icons;

  @include set-material-icons;

  &.md-18 {
    font-size: 1.125rem;
  }

  &.md-36 {
    font-size: 2.25rem;
  }

  &.md-48 {
    font-size: 3rem;
  }
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined', var(--font-family-material-icons); //stylelint-disable-line font-family-no-missing-generic-family-keyword
}

.material-icons-round {
  font-family: 'Material Icons Round', var(--font-family-material-icons); //stylelint-disable-line font-family-no-missing-generic-family-keyword
}

.material-icons-two-tone {
  font-family: 'Material Icons Two Tone', var(--font-family-material-icons); //stylelint-disable-line font-family-no-missing-generic-family-keyword
}

.material-icons-sharp {
  font-family: 'Material Icons Sharp', var(--font-family-material-icons); //stylelint-disable-line font-family-no-missing-generic-family-keyword
}

.material-icons-inline {
  font-size: inherit;
  line-height: 1;
}
