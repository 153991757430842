/* TODO
*  merge select and custom select
*  input-group-prepend or input-group-icon ? : bring icon within field
*/

%form-control {
  background-clip: padding-box;
  background-color: transparent;
  border-color: $textfield-border-color;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 $textfield-border-width;
  box-shadow: none;
  color: $textfield-color;
  display: block;
  font-size: $textfield-font-size;
  // todo height: $input-height;
  letter-spacing: $textfield-letter-spacing;
  line-height: $textfield-line-height;
  padding: $textfield-padding-y $textfield-padding-x calc(#{$textfield-padding-y} - #{$textfield-border-width});
  transition: border-color 15ms linear;
  width: 100%;

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $textfield-color; // todo
  }

  &::placeholder {
    color: $textfield-placeholder-color;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    border-style: dotted;
    color: $textfield-color-disabled;
    opacity: 1;

    @include hover-focus {
      border-color: $textfield-border-color;
      box-shadow: none;
    }
  }

  // stylelint-disable
  @include hover {
    border-color: $textfield-border-color-hover;
  }
  // stylelint-enable/

  &:focus {
    border-color: $textfield-border-color-focus;
    box-shadow: inset 0 ($textfield-border-width-focus * -1) 0 ($textfield-border-width * -1) $textfield-border-color-focus;
    outline: 0;
  }

  &:invalid:required {
    outline: 0;
  }
}

.form-control {
  @extend %form-control;

  &[type='file'] {
    max-height: $textfield-height;
  }
}

// Form control sizing

.form-control-lg {
  font-size: $textfield-font-size-lg;
  // height: $input-height-lg;    todo
  line-height: $textfield-line-height-lg;
  padding: $textfield-padding-y-lg $textfield-padding-x-lg calc(#{$textfield-padding-y-lg} - #{$textfield-border-width});

  &[type='file'] {
    max-height: $textfield-height-lg;
  }
}

.form-control-sm {
  font-size: $textfield-font-size-sm;
  // height: $input-height-sm;     todo
  line-height: $textfield-line-height-sm;
  padding: $textfield-padding-y-sm $textfield-padding-x-sm calc(#{$textfield-padding-y-sm} - #{$textfield-border-width});

  &[type='file'] {
    max-height: $textfield-height-sm;
  }
}

// Select and textarea

%form-select {
  appearance: none;

  @include media-moz-webkit {
    background-image: escape-svg($caret-bg);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: $textfield-select-bg-size $textfield-select-bg-size;
    padding-right: $textfield-select-bg-size;

    &[multiple],
    &[size]:not([size='1']) {
      background-image: none;
    }
  }
}

%form-textarea {
  border-color: $textfield-textarea-border-color;
  border-radius: $textfield-box-border-radius;
  border-width: $textfield-border-width;
  min-height: $textfield-box-height;
  padding: calc(#{$textfield-box-padding-y} - #{$textfield-border-width}) $textfield-box-padding-x;

  @include hover {
    border-color: $textfield-border-color-hover;
  }

  &:focus {
    border-color: $textfield-border-color-focus;
    box-shadow: inset $textfield-border-width-focus $textfield-border-width-focus 0 ($textfield-border-width * -1) $textfield-border-color-focus, inset ($textfield-border-width-focus * -1) ($textfield-border-width-focus * -1) 0 ($textfield-border-width * -1) $textfield-border-color-focus;
  }
}

// stylelint-disable selector-no-qualifying-type
select {
  &.form-control {
    @extend %form-select;

    &[multiple],
    &[size]:not([size='1']) {
      @extend %form-textarea;
      //height: auto;  todo
      padding: $spacer-sm 0;

      option {
        padding: 0 $spacer-lg;
      }
    }

    option {
      align-items: center;
      display: flex;
      font-size: $expansion-panel-font-size;
      height: $expansion-panel-height;
      letter-spacing: $expansion-panel-letter-spacing;
      //line-height: 1.5rem !important;

      &:hover {
        background: rgba($black, $overlay-hover-white-opacity);
      }

      &:active {
        background: rgba($black, $overlay-activated-white-opacity) !important;
      }
    }

    &:not([multiple]):not([size]):focus {
      background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg fill="#000" fill-opacity="#{$black-secondary-opacity}" transform="rotate(180)" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/><animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 0 0" to="180 0 0" dur="250ms"/></svg>'));
    }
  }
  // stylelint-enable

  &.form-control-lg {
    &[multiple],
    &[size]:not([size='1']) {
      padding: calc(#{$textfield-box-padding-y-lg} - #{$textfield-border-width}) $textfield-box-padding-x-lg;
    }
  }

  &.form-control-sm {
    &[multiple],
    &[size]:not([size='1']) {
      padding: calc(#{$textfield-box-padding-y-sm} - #{$textfield-border-width}) $textfield-box-padding-x-sm;
    }
  }
}

textarea {
  &.form-control {
    min-height: $textfield-height;
    //height: auto;

    &:not([rows='1']) {
      @extend %form-textarea;
    }
  }

  &.form-control-lg {
    min-height: $textfield-height-lg;

    &:not([rows='1']) {
      min-height: $textfield-box-height-lg;
      padding: calc(#{$textfield-box-padding-y-lg} - #{$textfield-border-width}) $textfield-box-padding-x-lg;
    }
  }

  &.form-control-sm {
    min-height: $textfield-height-sm;

    &:not([rows='1']) {
      min-height: $textfield-box-height-sm;
      padding: calc(#{$textfield-box-padding-y-sm} - #{$textfield-border-width}) $textfield-box-padding-x-sm;
    }
  }
}

// File
//
// Custom file input.

.custom-file {
  display: inline-block;
  height: $textfield-height;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  height: $textfield-height;
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 2;

  @include hover-focus {
    ~ .custom-file-label {
      border-bottom-color: $textfield-border-color-focus;
      box-shadow: inset 0 ($textfield-border-width-focus * -1) 0 ($textfield-border-width * -1) $textfield-border-color-focus;
    }
  }
}

.custom-file-label {
  @include text-truncate;
  @include transition-standard(border-color, box-shadow);

  border-bottom: $textfield-border-width solid $textfield-border-color;
  color: $textfield-hint-color;
  font-size: $textfield-font-size;
  height: $textfield-height;
  line-height: $textfield-line-height;
  padding: $textfield-padding-y ($material-icon-size + $selection-control-spacer-x) calc(#{$textfield-padding-y} - #{$textfield-border-width}) $textfield-padding-x;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  &::after {
    @include set-material-icons;

    content: $custom-file-button-icon;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.custom-select {
  @extend %form-control;
  @extend %form-select;

  &[multiple],
  &[size]:not([size='1']) {
    @extend %form-textarea;
  }
}

.custom-select-sm {
  // height: $custom-select-height-sm;
  font-size: $textfield-font-size-sm;
  line-height: $textfield-line-height-sm;
  padding: $textfield-padding-y-sm $textfield-select-bg-size calc(#{$textfield-padding-y-sm} - #{$textfield-border-width}) $textfield-padding-x-sm;

  &[multiple],
  &[size]:not([size='1']) {
    padding: calc(#{$textfield-box-padding-y-sm} - #{$textfield-border-width}) $textfield-box-padding-x-sm;
  }
}

.custom-select-lg {
  //  height: $custom-select-height-lg
  font-size: $textfield-font-size-lg;
  line-height: $textfield-line-height-lg;
  padding: $textfield-padding-y-lg $textfield-select-bg-size calc(#{$textfield-padding-y-lg} - #{$textfield-border-width}) $textfield-padding-x-lg;

  &[multiple],
  &[size]:not([size='1']) {
    padding: calc(#{$textfield-box-padding-y-lg} - #{$textfield-border-width}) $textfield-box-padding-x-lg;
  }
}

.form-control-file {
  @extend %form-control;

  max-height: $textfield-height;
}

.form-control-range {
  display: block;
  width: 100%;
}

// Validation

@each $state, $data in $form-validation-states {
  @include form-validation-state($state, map-get($data, color), map-get($data, icon));
}

.form-control[class*='valid'] {
  padding-right: calc(1.5rem + 0.5rem);
}
