// Clear icon

::-webkit-search-cancel-button,
::-webkit-clear-button {
  -webkit-appearance: none; // stylelint-disable-line
  background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg fill="#000" fill-opacity="#{$black-secondary-opacity}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'));
  color: $black-secondary;
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0;
  width: 1.5rem;
}

// Calendar icon

::-webkit-calendar-picker-indicator {
  background-image: escape-svg(url('data:image/svg+xml;charset=utf8,<svg fill="#000" fill-opacity="#{$black-secondary-opacity}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'));
  border-radius: 50%;
  color: rgba($black, $overlay-hover-white-opacity);
  cursor: pointer;
  height: 18px;
  margin-left: $spacer-sm;
  opacity: 1;
  width: 14px;

  &:hover {
    box-shadow: 0 0 0 4px rgba($black, $overlay-hover-white-opacity);
  }
}

// Transparency for autocompleted inputs

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: $textfield-color;
}
