// Checkbox and radio button

.form-check {
  display: block;
  margin-bottom: $textfield-margin-y;
  padding-left: $form-check-input-gutter;
  position: relative;
}

.form-check-input {
  margin-top: ($font-size-base * $line-height-base - $font-size-base) / 3 * 2; //$form-check-input-margin-y;
  margin-left: -$form-check-input-gutter;
  position: absolute;

  // Use disabled attribute instead of :disabled pseudo-class
  // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
  &[disabled] ~ .form-check-label {
    color: $textfield-color-disabled;
  }
}

.form-check-label {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  //margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  display: inline-flex;
  margin-right: $form-check-inline-margin-x;
  margin-bottom: 0;

  .form-check-input {
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
    position: static;
  }
}

// Form group

.form-group {
  margin-bottom: $form-group-margin-y;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($textfield-margin-x / -2); //-$form-grid-gutter-width / 2;
  margin-left: ($textfield-margin-x / -2);

  > .col,
  > [class*='col-'] {
    padding-right: ($textfield-margin-x / 2);
    padding-left: ($textfield-margin-x / 2);
  }
}

// Inline

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;

  .form-check {
    margin-bottom: 0;
    width: 100%; //auto
  }

  // stylelint-disable
  @include media-breakpoint-up(sm) {
    label {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for 'inline'
    .form-group {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      vertical-align: middle;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group,
    .custom-select {
      width: auto;
    }

    .form-check {
      align-items: center;
      display: flex;
      justify-content: center;
      padding-left: 0;
      width: auto;
    }

    .form-check-input {
      flex-shrink: 0;
      margin-top: 0;
      margin-right: $form-check-input-gutter;
      margin-left: 0;
      position: relative;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }

    .custom-control-label {
      margin-bottom: 0;
    }
  }
  // stylelint-enable
}

// Label

.col-form-label {
  color: inherit;
  font-size: $font-size-base; // todo
  line-height: ($textfield-font-size * $textfield-line-height / $font-size-base); //$input-line-height
  // margin-bottom: 0;
  padding-top: $textfield-padding-y;
  padding-bottom: $textfield-padding-y;
}

.col-form-label-lg {
  font-size: ($font-size-base / $textfield-font-size * $textfield-font-size-lg); //@include font-size($input-font-size-lg)
  line-height: ($textfield-line-height-lg / ($font-size-base / $textfield-font-size)); //$input-line-height-lg;
  padding-top: $textfield-padding-y-lg;
  padding-bottom: $textfield-padding-y-lg;
}

.col-form-label-sm {
  font-size: ($font-size-base / $textfield-font-size * $textfield-font-size-sm); //@include font-size($input-font-size-sm);
  line-height: ($textfield-line-height-sm / ($font-size-base / $textfield-font-size)); //$input-line-height-sm;
  padding-top: $textfield-padding-y-sm;
  padding-bottom: $textfield-padding-y-sm;
}

// Misc

.form-text {
  color: $textfield-caption-color;
  display: block;
  font-size: $font-size-caption;
  font-weight: $font-weight-caption;
  letter-spacing: $letter-spacing-caption;
  line-height: normal;
  padding-left: $textfield-box-padding-x;

  &::before {
    content: '';
    display: inline-block;
    height: 1rem;
    vertical-align: 0;
    width: 0;
  }

  /* [class~='textfield-box'] &,
  [class~='textfield-box'] ~ & {
    padding-left: $textfield-box-padding-x;
  } */
}

// Readonly control as plain text

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-radius: 0;
  border-width: 0 0 $textfield-border-width;
  box-shadow: none;
  color: $textfield-plaintext-color;
  display: block;
  font-size: $textfield-font-size;
  line-height: $textfield-line-height;
  padding: $textfield-padding-y 0 calc(#{$textfield-padding-y} - #{$textfield-border-width});
  width: 100%;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}
