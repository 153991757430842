//
// Base styles
//
// Not sure it is OK to look like : https://material.io/design/components/banners.html#specs

.alert {
  @include border-radius($alert-border-radius);

  //border: 0;
  border: 1px solid $black-divider;
  color: $black-primary;
  display: block;
  margin-bottom: $alert-margin-y;
  padding: $alert-padding-y $alert-padding-x;
  position: relative;
}

// Headings for larger alerts
.alert-link {
  color: inherit;
  font-weight: $alert-link-font-weight;

  @include hover-focus-active {
    color: inherit;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $close-font-size + $alert-padding-x * 2;

  // Adjust close link position
  .close {
    color: $black-hint;
    padding: ($alert-padding-y - ($close-font-size - $font-size-base * $line-height-base) / 2) $alert-padding-x;
    position: absolute;
    top: 0;
    right: 0;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.
@each $color, $values in $theme-colors {
  .alert-#{$color} {
    //background-color: theme-color-light($color);
    border-left: 6px solid theme-color($color);
    //color: color-yiq(theme-color-light($color));
  }
}

// @mixin alert-variant not used
