// stylelint-disable declaration-no-important
// Deprecated in BS 441, keep in Material 2 https://github.com/twbs/bootstrap/pull/28938/commits/dfdefb381815e8f6f2295e021543663da446ccad

@mixin bg-variant($class, $color, $color-dark: '') {
  @if ($color-dark != '') {
    a#{$class} {
      @include hover-focus-active {
        background-color: $color-dark !important;
      }
    }
  }

  #{$class} {
    background-color: $color !important;
  }
}
