// todo : table numeric cell

.data-table {
  border: $table-border-width solid $table-border-color;
  border-radius: $border-radius;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: $table-margin-y;
  max-width: 100%;
  overflow: auto;
}

.table {
  background-color: $table-bg;
  border: 0;
  font-size: $table-font-size;
  width: 100%;

  td,
  th {
    border-top: $table-border-width solid $table-border-color;
    font-weight: $font-weight-normal;
    padding-right: $table-cell-padding-x;
    padding-left: $table-cell-padding-x;
    vertical-align: middle;
  }

  tbody {
    td,
    th {
      height: $table-tbody-cell-height;
      letter-spacing: 0.0178571429em;
      line-height: 1.25rem;
    }
  }

  tfoot {
    color: $table-tfoot-color;

    td,
    th {
      font-size: $table-tfoot-font-size;
      font-weight: $table-tfoot-font-weight;
      height: $table-tfoot-cell-height;
    }
  }

  thead {
    td,
    th {
      font-weight: $table-thead-font-weight;
      height: $table-thead-cell-height;
      letter-spacing: $table-thead-letter-spacing;
      line-height: 1.375rem;
    }
  }

  // stylelint-disable
  .card > & {
    &:first-child {
      &,
      > :first-child,
      > :first-child > tr:first-child {
        @include border-top-radius($card-border-radius);
      }

      > :first-child > tr:first-child {
        td,
        th {
          &:first-child {
            border-top-left-radius: $card-border-radius;
          }

          &:last-child {
            border-top-right-radius: $card-border-radius;
          }
        }
      }
    }

    &:last-child {
      &,
      > :last-child,
      > :last-child > tr:last-child {
        @include border-bottom-radius($card-border-radius);
      }

      > :last-child > tr:last-child {
        td,
        th {
          &:first-child {
            border-bottom-left-radius: $card-border-radius;
          }

          &:last-child {
            border-bottom-right-radius: $card-border-radius;
          }
        }
      }
    }
    // stylelint-enable
  }

  .table {
    border-top: $table-border-width solid $table-border-color;
  }

  > :first-child > tr:first-child {
    td,
    th {
      border-top: 0;
    }
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  .card > & {
    border: 0;
  }
}

.table-borderless {
  td,
  th,
  .table {
    border: 0;
  }
}

.table-sm {
  tbody {
    td,
    th {
      height: $table-sm-tbody-cell-height;
    }
  }

  tfoot,
  thead {
    td,
    th {
      height: $table-sm-thead-cell-height;
    }
  }
}

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-bg-accent;
  }
}

// Table backgrounds

@each $color, $values in $theme-colors {
  .table-#{$color} {
    &,
    > td,
    > th {
      background-color: theme-color-light($color);
      color: color-yiq(theme-color-light($color));
    }

    .table-hover & {
      @include hover {
        &,
        > td,
        > th {
          background-color: theme-color($color);
          color: color-yiq(theme-color($color));
        }
      }
    }
  }
}

// Placed here because it has to come after the striping styles

.table-hover,
.data-table {
  tbody tr {
    &:not(.table-active):hover {
      background-color: $table-bg-hover;
    }
  }
}

.table-active {
  &,
  > td,
  > th {
    background-color: $table-bg-active;
  }
}

// stylelint-disable-next-line no-duplicate-selectors
.table {
  .thead-dark {
    td,
    th {
      background-color: $table-dark-bg;
      color: $table-dark-color;
    }
  }

  .thead-light {
    td,
    th {
      background-color: $table-thead-bg;
      //color: $table-thead-color;
    }
  }
}

.table-dark {
  background-color: $table-dark-bg;
  color: $table-dark-color;

  &.table-bordered {
    border-color: $table-dark-border-color;
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-dark-bg-accent;
    }
  }

  &.table-hover {
    tbody tr {
      @include hover {
        background-color: $table-dark-bg-hover;
      }
    }
  }

  tbody,
  tfoot {
    color: inherit;
  }

  thead {
    color: rgba($white, 0.6);
  }

  td,
  th,
  .table {
    border-color: $table-dark-border-color;
  }
}

// Table responsive

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        overflow-x: auto;
        width: 100%;
        -webkit-overflow-scrolling: touch;

        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}
